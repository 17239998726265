<template>
  <div id="root">
    <header>
      <div class="title-area">
        <div class="title-box">
          <a href="/" class="previous">
            <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
            내 저장소</a
          >
          <h2>진료내역</h2>
        </div>
      </div>
    </header>

    <main>
      <h6 class="text-center" style="margin-bottom: 1.5rem">
        간편인증 방법을 선택하세요.
      </h6>

      <b-container>
        <b-row
          style="margin-bottom: 1.5rem;"
          align-v="center"
          @click="goToNextStep"
        >
          <b-col>
            <b-img
              v-bind="mainProps"
              rounded
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/icon/kakao.png"
              id="kakao"
            />
          </b-col>
          <b-col class="text-left">
            카카오톡
          </b-col>
        </b-row>

        <b-row
          style="margin-bottom: 1.5rem"
          align-v="center"
          @click="goToNextStep"
        >
          <b-col>
            <b-img
              v-bind="mainProps"
              rounded
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/icon/naver.png"
              id="naver"
            />
          </b-col>
          <b-col class="text-left">
            네이버
          </b-col>
        </b-row>

        <b-row
          style="margin-bottom: 1.5rem"
          align-v="center"
          @click="goToNextStep"
        >
          <b-col>
            <b-img
              v-bind="mainProps"
              rounded
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/icon/toss.png"
              id="toss"
            />
          </b-col>
          <b-col class="text-left">
            토스
          </b-col>
        </b-row>

        <b-row
          style="margin-bottom: 1.5rem"
          align-v="center"
          @click="goToNextStep"
        >
          <b-col>
            <b-img
              v-bind="mainProps"
              rounded
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/icon/pass.png"
              id="pass"
            />
          </b-col>
          <b-col class="text-left">
            통신사 패스 <br />
            (SKT, KT, LG U+)
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: "",
      mainProps: { width: 75, height: 75, class: "m1" }
    };
  },
  methods: {
    goToNextStep() {
      this.$router.push("2");
    }
  }
};
</script>

<style>
/* root-space */
#root {
  margin: 0px auto;
  width: 93%;
}

main {
  width: 100%;
  /* border: 1px solid; */
}

/* title area */
.title-area {
  width: 100%;
  border-radius: 0 0 25px 25px;
  padding-bottom: 20px;
  position: relative;
  background: #fcfcfc;
}

.title-box {
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 5px;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 50px;
}

.title-box a.previous {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}

.title-box h2 {
  font-size: 18px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
</style>
